import {Card,CardMedia,Divider,Grid,Tooltip,Typography,} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
//import EditPackagesDialog from "./EditPackagesDialog";
import axios from "../../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
//import EditPackagesDialogEdit from "./EditPackagesDialogEdit";
import DeleteConfirmDialog from "../DeleteConfirmDialog";
import CommunityDialogEdit from "./CommunityDialogEdit";
import CommunityDialog from "./CommunityDialog";
  
  const label = { inputProps: { "aria-label": "Switch demo" } };
  
  export default function EditPackages() {
    const alert = useAlert();
    const [communities, setCommunities] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
  
    const getCommunities = () => {
      setLoading(true);
      axios(`Izra/get_user_communities`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            setLoading(false);
          } else {
            setCommunities(res.data.data);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    };
  
    React.useEffect(() => {
      getCommunities();
    }, []);
  
    const handleCheck = (index, checked) => {
      let formdata = new FormData();
      formdata.append("id", index);
      formdata.append("status", checked);
      axios(`Izra/update_group_status`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status) {
            alert.success(res.data.message);
            getCommunities();
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    };
  
    return (
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <div>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={7}>
              <Typography variant="h6" fontWeight={600} color="primary">
                Community List
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={4}>
              <Typography textAlign="end">
                 <CommunityDialog Lists={getCommunities}/>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {communities.length > 0 ? (
              <Grid item xs={12}>
                {communities.map((data, index) => (
                  <Grid
                    container
                    item
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={2}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="70"
                          width="70"
                          alt="Sa`aeh Gallery"
                          image={data.image}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography>{data.title}</Typography>
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <Typography>{data.desc}</Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                      <Tooltip title="Status" aria-label="Edit Status">
                        <Switch
                          {...label}
                          onChange={(e) => handleCheck(data.id, e.target.checked)}
                          defaultChecked={data.status == 1 ? true : false}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2} md={1}>
                       <CommunityDialogEdit id={data.id} Lists={getCommunities}/>
                    </Grid>
                    <Grid item xs={2} md={1}>
                       <DeleteConfirmDialog type="communities" id={data.id} Lists={getCommunities}/> 
                    </Grid>
  
                    <Grid item xs={12} pb={2}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="caption" color="GrayText">
                  No Community found
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      </LoadingOverlay>
    );
  }
  