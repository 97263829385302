import React from "react";
import Footer from "../footer/Footer";
import AgricultureTourismCarousel from "./AgricultureTourismCarousel";
import AgroStories from "./AgroStories";
import Banner from "./Banner";
import ExploreNearby from "./ExploreNearby";
import FisheriesCarousel from "./FisheriesCarousel";
import OpportunitiesCarousel from "./OpportunitiesCarousel";
import OurApproach from "./OurApproach";
import OurExperts from "./OurExperts";
import OurMission from "./OurMission";
import CommuniteesCarousel from "./CommuniteesCarousel";
import Workshops from "./Workshops";
import HikingTrips from "./HikingTrips";

export default function Home() {
  return (
    <div>
      <Banner />
      <ExploreNearby />
      <OurMission />
      <OurApproach />
      <OpportunitiesCarousel />
      <FisheriesCarousel />
      <AgricultureTourismCarousel />
      <Workshops />
      <HikingTrips />
      <AgroStories />
      <OurExperts />
      <CommuniteesCarousel />
      <Footer />
    </div>
  );
}
