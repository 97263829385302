import React, { useState } from "react";
import {useParams} from "react-router-dom";
import Header from "../../header/Header";
import {Box,Container,Typography,Card,Tab,Tabs,Grid,createTheme,ThemeProvider,CardContent} from "@mui/material/";
import PropTypes from "prop-types";
import axios from "../../utils/Axios"; //import axios config
import { makeStyles } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import logo_skeleton from "../../images/skeleton/logo_skeleton.webp";
 import MyAccountEditGallery from "./MyAccountEditGallery";
 import MyAccountEditAwards from "./EditAwards/MyAccountEditAwards";
// import MyAccountEditOverviewTextEditor from "./MyAccountEditOverviewTextEditor";
import LoadingOverlay from "react-loading-overlay";
import MyAccountEditBasicDetails from "./MyAccountEditBasicDetails";
import MyAccountEditOverview from "./MyAccountEditOverview";
import MyAccountEditCustomeFields from "./MyAccountEditCustomeFields";
import MyAccountEditNewsAndEvents from "./EditNewsAndEvents/MyAccountEditNewsAndEvents";
// import CustomeFieldDialog from "./CustomeFieldDialog";
// import CustomeFieldDialogEdit from "./CustomeFieldDialogEdit";
// import MyAccountEditCourses from "./MyAccountEditCourses";
// import DeleteConfirmDialog from "./DeleteConfirmDialog";

const theme = createTheme({
  palette: {
    primary: {
      light: "#833baa",
      main: "#572672",
      dark: "#381849",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

window.scrollTo(0, 0);

export default function MyAccountEdit(props) {

  let params = useParams();
  const [saahe_id] = React.useState(params.id ? params.id: "");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  
  const [customfields, setCustomlist] = React.useState([]);

  React.useEffect(() => {
    customList(saahe_id);
  }, [page, rowsPerPage]);

  const [loading, setLoading] = React.useState(false);
  
  const customList = (saahe_id) => {
    setLoading(true);
    axios(`Home/get_custom_fields?saahe_id=${saahe_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCustomlist(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };
  

  return (
    <div>
      <Header />
      <Box
        sx={{ bgcolor: "#F6F9FC" }}
        py={{ xs: "1rem", sm: "2rem", md: "3rem", lg: "4rem" }}
      >
        <Container maxWidth="xl">
          <ThemeProvider theme={theme}>
            <Box
              pt={{ xs: "100px", sm: "60px", md: "48px", lg: "48px" }}
              pb={{ xs: "16px", sm: "16px", md: "48px", lg: "48px" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Tabs
                        className="MyAccountEditTabs"
                        orientation="vertical"
                        fullWidth
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                      >
                        <Tab
                          className="tabac"
                          label="Basic Details"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="tabac"
                          label="Overview"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="tabac"
                          label="Gallery"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="tabac"
                          label="Awards and Accreditions"
                          {...a11yProps(3)}
                        />
                        <Tab
                          className="tabac"
                          label="News and Events"
                          {...a11yProps(4)}
                        />
                        <Tab
                          className="tabac"
                          label="Custome Fields +"
                          {...a11yProps(5)}
                        ></Tab>
                      </Tabs>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={8} lg={9}>
                  <Card>
                    <TabPanel value={value} index={0}>
                      <MyAccountEditBasicDetails />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <MyAccountEditOverview />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <MyAccountEditGallery />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <MyAccountEditAwards/>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <MyAccountEditNewsAndEvents saahe_id={saahe_id} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <MyAccountEditCustomeFields />                    
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </ThemeProvider>
        </Container>
      </Box>
    </div>
  );
}
