import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import axios from "../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const theme = createTheme();

export default function Registration(props) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  const history = useNavigate();                   
  const alert = useAlert();
  const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [email, setEmail] = React.useState(
    props &&
      props.location &&
      props.location.state &&
      props.location.state.email
      ? props.location.state.email
      : ""
  );
  const [redirect_url] = React.useState(
    props &&
      props.location &&
      props.location.state &&
      props.location.state.prevLocation
      ? props.location.state.prevLocation
      : "/"
  );

  const handleSubmit2 = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("password", password);
    formdata.append("email", email);
    formdata.append("phone", phone);
    formdata.append("location", location);
    axios("Login/register", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (!response.data.error) {
          localStorage.setItem(
            "data",
            JSON.stringify(response.data.data.api_key)
          );
          alert.success("Login Successfull");
          history({
            pathname: redirect_url,
            state: { data: true },});
        } else {
          alert.error(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid
          item
          xs={false}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 20,
              mx: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              component={"img"}
              pb={8}
              width={200}
              src="https://jobportal.gligx.com/img/saaeh/logo.webp"
              alt=""
              to="/home"
            />
            <Typography component="h1" variant="h5">
              Finish signing up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                label=" Name"
                name="Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                autoComplete="text"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name=" Email"
                label=" Email"
                autoComplete=" Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <PhoneInput
                className="phoneSign"
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                type="password"
                name="Password"
                label="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="Location"
                label="Location"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                value={location}
              />
              <Grid container item spacing={3}>
                <Grid item xs={12} md={6}>
                  <Link to="/login">
                    <Button
                      type="submit"
                      fullWidth
                      variant="standard"
                      color="primary"
                    >
                      Login
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12} md={6} className="btn_black">
                  <Typography textAlign="end">
                    <Button
                      onClick={handleSubmit2}
                      className="regBtn"
                      variant="contained"
                      sx={{ p: 0.3, minWidth: "200px" }}
                      endIcon={<ArrowForwardIcon />}
                    >
                      Submit
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage:
              "url(https://jobportal.gligx.com/img/saaeh/reg_background.webp)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              my: 20,
              mx: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Why Sa’aeh?
            </Typography>
            <Typography color="GrayText" variant="body2" gutterBottom>
              Plus Sa’aeh will be the major platform for so many aspects and
              economical sectors , the country would like to take care of and
              develop :
            </Typography>
            <Box color="GrayText">
              <List sx={{ width: "100%", maxWidth: 360 }} aria-label="contacts">
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Touris, with its various types." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Investment." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Freelancing." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Advertising." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Recruitment." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sustainability." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Communication." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Financial Transaction." />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
