import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import DetailAccordionGallery from "../DetailAccordionGallery";
import DetailAccordionReviews from "../DetailAccordionReviews";
import axios from "../../utils/Axios"; //import axios config
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageListItem from "@mui/material/ImageListItem";
import { Tooltip } from "@material-ui/core";
import parse from "html-react-parser";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "grey.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#013A4B",
    },
    secondary: {
      main: "#434444",
    },
  },
});

export default function ExpertDetailAccordion({saaheId,setSaaheId,getrating,ratings,total}) {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState([]);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    getProfile(saaheId);
  }, []);

  const getProfile = (saaheId) => {
    setLoading(true);
    axios(`Izra/get_list_details?id=${saaheId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setSaahe(res.data.data[0]);
          console.log(res.data.data[0]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ bgcolor: "#F7F7F7", pt: "48px", pb: "48px" }}>
        <Container>
          <ThemeProvider theme={theme}>
            <Box sx={{ width: "100%" }}>
              {saahe.custom_fields &&
                saahe.custom_fields.map((item, index) => {
                  return (
                    <Accordion
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        sx={{ width: "100%", flexShrink: 0 }}
                      >
                        <Typography variant="h6" pl="8px">
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {parse(item.description)}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              {saahe.awards && saahe.awards.length > 0 && (
              <Accordion
                expanded={expanded === "1"}
                onChange={handleChange("1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  sx={{ width: "100%", flexShrink: 0 }}
                >
                  <Typography
                    variant="h6"
                    pl="8px"
                    sx={{ width: "100%", flexShrink: 0 }}
                  >
                    Awards and Accreditations
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      {saahe.awards &&
                        saahe.awards.map((item) => {
                          return (
                            <Grid item xs={2} sm={4} md={4}>
                              <Card>
                                <CardActionArea>
                                  <CardMedia
                                    component="img"
                                    width="fit-content"
                                    height="240"
                                    image={item.images}
                                    alt="education listing"
                                  />
                                  <CardContent>
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="div"
                                      display="block"
                                      whiteSpace="nowrap"
                                      textOverflow="ellipsis"
                                      overflow="hidden"
                                    >
                                      {item.title}
                                    </Typography>
                                    <Tooltip title={item.description}>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        whiteSpace="nowrap"
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                      >
                                        {item.description}
                                      </Typography>
                                    </Tooltip>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {item.awd_date}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
              )}
              {saahe.gallery && saahe.gallery.length > 0 && (
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                  sx={{ width: "100%", flexShrink: 0 }}
                >
                  <Typography variant="h6" pl="8px">
                    Gallery
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                     <DetailAccordionGallery saaheId={saaheId} /> 
                  </Typography>
                </AccordionDetails>
              </Accordion>
              )}
              {saahe.events && saahe.events.length > 0 && (
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                  sx={{ width: "100%", flexShrink: 0 }}
                >
                  <Typography variant="h6" pl="8px">
                    News and Events
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {saahe.events &&
                      saahe.events.map((item) => {
                        return (
                          <Grid item xs={2} sm={4} md={4}>
                            <Card>
                              <CardActionArea>
                                <ImageListItem
                                  key={item.img}
                                  sx={{ width: "inherit" }}
                                >
                                  <CardMedia
                                    component="img"
                                    width="fit-content"
                                    height="240"
                                    image={item.images}
                                    alt={item.title}
                                    loading="lazy"
                                    borderRadius="30px"
                                  />
                                  <ImageListItemBar
                                    subtitle={item.event_date}
                                    title={item.title}
                                  />
                                </ImageListItem>
                                <CardContent>
                                  <Tooltip title={item.description}>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                      component="p"
                                      whiteSpace="nowrap"
                                      textOverflow="ellipsis"
                                      overflow="hidden"
                                    >
                                      {item.description}
                                    </Typography>
                                  </Tooltip>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              )}
              {ratings && ratings.length > 0 && (
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5 bh-header"
                  sx={{ width: "100%", flexShrink: 0 }}
                >
                  <Typography variant="h6" pl="8px">
                    Community Rating
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                     <DetailAccordionReviews getrating={getrating} total={total} ratings={ratings} saaheId={saaheId} getProfile={getProfile} /> 
                  </Typography>
                </AccordionDetails>
              </Accordion>
              )}
            </Box>
          </ThemeProvider>
        </Container>
      </Box>
    </div>
  );
}
