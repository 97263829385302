import { Container, Grid, Typography, Paper } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#2E5800",
  ...theme.typography.body2,
  padding: theme.spacing(5),
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  alignItems: "center",
  textAlign: "center",
  color: "#fff",
}));

export default function OurMission() {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid Item xs={12}>
          <Item>
            <Container>
              <Grid container spacing={2} justifyContent="center" pt={1}>
                <Grid Item xs={12} md={6}>
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    textTransform="uppercase"
                  >
                     Our Mission
                  </Typography>
                  <Typography variant="h6" component="p">
                    Using the power of science, exploration, education, and
                    storytelling to illuminate and protect 
                  </Typography>
                  <Typography 
                    variant="h6"
                    component="p"
                    color="#7EC401"
                  > the wonder of our world </Typography>
                </Grid>
              </Grid>
            </Container>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
}
