import {Avatar,Divider,Grid,ListItemAvatar,ListItemText,Typography,} from "@mui/material";
import React from "react";
import {useParams} from "react-router-dom";
import Switch from '@mui/material/Switch';
import MyAccountEditNewsAndEventsDialog from "./MyAccountEditNewsAndEventsDialog";
import axios from "../../../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
 import MyAccountEditNewsAndEventsDialogEdit from "./MyAccountEditNewsAndEventsDialogEdit";
import DeleteConfirmDialog from "../DeleteConfirmDialog";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";
const theme = createTheme({
  palette: {
    primary: {
      light: "#9ab674",
      main: "#6f973d",
      dark: "#2E5800",
      contrastText: "#fff",
    },
    secondary: {
      light: "#acacac",
      main: "#676767",
      dark: "#3f3a3a",
    },
  },
}); 
export default function MyAccountEditNewsAndEvents({saahe_id}) {

  const alert = useAlert();
  const [news, setNews] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getNews = () => {
    setLoading(true);
    axios(`Izra/get_user_news?id=${saahe_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setNews(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };


  React.useEffect(() => {
    getNews();
  }, []);


  const handleCheck = (index, checked) => {
      let formdata = new FormData();
      formdata.append("news_id", index);
      formdata.append("status", checked);
      axios(`Izra/update_news_status`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status) {
            alert.success(res.data.message);
            getNews();
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
  };

  return (
    <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
    <div>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container item spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" color="GaryText">
            News and Events
          </Typography>
          <Typography variant="caption" color="GrayText">
            Manage your News and Events details
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign="end">
              <MyAccountEditNewsAndEventsDialog saahe_id={saahe_id} getNews={getNews}/>
          </Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Divider />
        </Grid>
        {news.length > 0 ? (
        <Grid item xs={12}>
        {news.map((data, index) => (
          <Grid container spacing={1} py={1}>
            <Grid item xs={2} sm={1}>
              <ListItemAvatar>
                <Avatar>
                  <Avatar src={data.images} alt="News and Events title" />
                </Avatar>
              </ListItemAvatar>
            </Grid>
            <Grid item xs={10} sm={3}>
              <ListItemText primary={data.title} />
            </Grid>
            <Grid item xs={5} sm={3}>
            {data.event_date}
            </Grid>
            <Grid item xs={4} sm={3}>
            <Switch  
            onChange={(e) =>
              handleCheck(data.id, e.target.checked)
            }
            defaultChecked={data.status==1 ? true : false}
            />
            </Grid>
            <Grid item xs={1} sm={1}>
              <MyAccountEditNewsAndEventsDialogEdit news_id={data.id} saahe_id={saahe_id} getNews={getNews}/>
            </Grid>
            <Grid item xs={2} sm={1}>
              <DeleteConfirmDialog type="news" id={data.id} Lists={getNews}/>
            </Grid>
          </Grid>
            ))}
          {/* <Box display="flex" justifyContent="center">
            <Stack spacing={2}>
              <Pagination />
            </Stack>
          </Box> */}

        </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="caption" color="GrayText">
              No News and Events found
            </Typography>
            </Grid>
        )}
      </Grid>
      </ThemeProvider>
    </div>
    </LoadingOverlay>
  );
}
