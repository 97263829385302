import {Button,Card,CardMedia,Divider,Grid,IconButton,TextField,Tooltip,Typography,Stack,Switch,styled} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import axios from "../../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";
const theme = createTheme({
  palette: {
    primary: {
      light: "#9ab674",
      main: "#6f973d",
      dark: "#2E5800",
      contrastText: "#fff",
    },
    secondary: {
      light: "#acacac",
      main: "#676767",
      dark: "#3f3a3a",
    },
  },
});

const Input = styled('input')({
  display: 'none',
});
const label = { inputProps: { "aria-label": "Switch demo" } };

export default function MyAccountEditGallery() {

  let params = useParams();
  const [saahe_id] = React.useState(params.id ? params.id: "");

  const alert = useAlert();
  const [gallery, setGallery] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getGallery = () => {
    setLoading(true);
    axios(`Izra/get_all_gallery?id=${saahe_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setGallery(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };


  React.useEffect(() => {
    getGallery();
  }, [JSON.parse(localStorage.getItem("data"))]);


  const handleCheck = (index, checked) => {
    let formdata = new FormData();
    formdata.append("image_id", index);
    formdata.append("status", checked);
    axios(`Izra/update_gallery_status`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getGallery();
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
};

  const [title, setTitle] = React.useState([]);

  const [state, setState] = React.useState({
    firstName: ""
  })

  const setChangeTitle = (index, dataType, value) => {
    let arr = [];
    const newState = title.map((item, i) => {
      arr.push(value);
    });

    setTitle(arr);
  };

  const handleChange = (index, dataType, value) => {
    const newState = gallery.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value};
      }
      return item;
    });

    setGallery(newState);
  };
  

  var loadFile = (event) => {
    if (event.target.files[0]) {
      event.preventDefault();
      let formdata = new FormData();
      formdata.append("id", saahe_id);
      formdata.append("logo", event.target.files[0]);
      axios(`Izra/add_gallery`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
      .then((res) => {
        if (res.data.error) {
          alert.error(res.data.message);
          setLoading(false);
        } else {
          getGallery();
          alert.success(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
      }
  };

  const updategallery = () => {
    let formdata = new FormData();
    formdata.append("id", saahe_id);
    formdata.append("title", JSON.stringify(gallery));
    axios(`Izra/update_gallery`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getGallery();
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
};


  return (

    <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
    <div>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container item spacing={3}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" fontWeight={600} color="primary">
            Gallery
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <label htmlFor="contained-button-file">
              <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={loadFile}/>
              <Button variant="outlined" component="span">
                Upload Photos {" "} <PhotoCamera />
              </Button>
            </label>
            {/* <label htmlFor="icon-button-file">
              <Input accept="image/*" id="icon-button-file" type="file" />
              <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
              </IconButton>
            </label> */}
          </Stack>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography textAlign="end">
            <Button variant="contained" color="primary" onClick={updategallery}>
              Save
            </Button>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {gallery.length > 0 ? (
          <Grid item xs={12}>
        {gallery.map((data, index) => (
         <Grid container item spacing={3}>
        <Grid item xs={12} md={2}>
          <Card>
            <CardMedia
              component="img"
              alt="Sa`aeh Gallery"
              image={data.image}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField size="small"
                fullWidth
                label="Name"
                variant="outlined"
                value={data.title}
                onChange={(e) =>
                  handleChange(index, "title", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField size="small"
                fullWidth 
                label="Description"
                multiline
                rows={3}
                defaultValue="Description"
                variant="outlined"
                value={data.description}
                onChange={(e) =>
                  handleChange(index, "description", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField size="small"
            fullWidth
            id="outlined-basic"
            label="Link"
            variant="outlined"
            value={data.link}
            onChange={(e) =>
              handleChange(index, "link", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Tooltip title="Status" aria-label="delete">
            <Switch {...label} 
            onChange={(e) =>
              handleCheck(data.id, e.target.checked)
            }
            defaultChecked={data.status==1 ? true : false} />
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={1} sx={{ mt: 1}}>
          <DeleteConfirmDialog  type="gallery" id={data.id} Lists={getGallery}/>
        </Grid>
        <Grid item xs={12} pb={2}>
          <Divider />
        </Grid>
        </Grid>
        ))}
        </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography>
              No Gallery Found
            </Typography>
          </Grid>
        )}
      </Grid>
      </ThemeProvider>
    </div>
    </LoadingOverlay>
  );
}
