import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "../utils/Axios"; //import axios config
import { Link, useNavigate } from "react-router-dom";
import { APP_URL } from "../constants/Constants";
import { useAlert } from "react-alert";
import IconButton from "@material-ui/core/IconButton";
import logoIcon from "../images/logo-icon.webp";
import { Box } from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6f973d",
    },
    secondary: {
      main: "#676767",
    },
    linkcolor: {
      main: "#013A4B",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const history = useNavigate();
  const alert = useAlert();
  const [email, setEmail] = React.useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("email", email);
    formdata.append("url", APP_URL);
    axios("Login/user_forgotpassword", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.status === true) {
          history({
            pathname: "/forgotpassword",
            state: { data: true },
          });
          alert.success("Email Sent Successfully");
        } else {
          alert.error(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="#6e973d42"
        p={1}
        sx={{ height: "100vh" }}
      >
        <Box p={1} bgcolor="white" borderRadius="8px">
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Link to="/education">
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                >
                  <Avatar alt="Saaeh logo" src={logoIcon} />
                </IconButton>
              </Link>
              <Box p={2} mb={3}>
                <Typography component="h1" variant="h5" color="primary">
                  Forgot Password
                </Typography>
              </Box>

              <form className={classes.form} noValidate>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  className={classes.submit}
                >
                  Forgot Password
                </Button>
              </form>
              <Box p={2}>
                <Link to="/Login">
                  <Button
                    variant="standard"
                    color="secondary"
                    startIcon={<ArrowBackIosIcon />}
                  >
                    Back to Login
                  </Button>
                </Link>
              </Box>
            </div>
            <Box mt={8}>
              <Typography variant="body2" color="textSecondary" align="center">
                Copyright © Gligx 2022.
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
