import React from "react";
import Footer from "../../footer/Footer";
import { useNavigate,useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Container } from "@material-ui/core";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Rating from "@mui/material/Rating";
import ReviewsIcon from "@mui/icons-material/Reviews";
import PropTypes from "prop-types";
import axios from "../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import LoadingOverlay from "react-loading-overlay";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Grid } from "@mui/material";
import Slider, { SliderThumb } from "@mui/material/Slider";
import Header from "../../header/Header";

function valuetext(value) {
  return `${value}°C`;
}
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid #ccc",
  fullWidth: true,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        m: 1,
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};

export default function Groups(props) {
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useNavigate();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);

  const [lists, setlists] = React.useState([]);
  const [tutors, settutors] = React.useState([]);
  const [searchlist, setSearchList] = React.useState([]);
  const [type, setTtype] = React.useState(0);


  const tutorfilterChange = (value) => {
    setTtype(value);
  };

  React.useEffect(() => {
    all_list();
    getTypes();
  }, [page, rowsPerPage, type, searchlist]);

  const all_list = () => {
    setLoading(true);
    axios(
      `Izra/all_groups?page=${page}&per_page=${rowsPerPage}&type=${type}&keyword=${searchlist}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        window.scrollTo(0, 0);
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setlists([]);
            setTotal(res.data.total);
          } else {
            setLoading(false);
            setlists(res.data.data);
            setTotal(res.data.total);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const DetailView = (id, type) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      history(`/CommuniteesDetail/${id}`);
    } else {
      alert.error("Please login to view this page");
    }
  };

  const getTypes = () => {
    setLoading(true);
    axios(`Izra/get_types`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          settutors(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
      <Box
        className="caro_bg"
        sx={{ bgcolor: "#F7F7F7", pt: "100px", pb: "48px" }}
      >
        <Container>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                  <Typography variant="h5" component="div" gutterBottom>
                    List of Groups
                  </Typography>
                  <Typography variant="body2" color="GrayText" component="div">
                  Health Groups Listing
                  </Typography>
                </Grid>
              </Grid>
            </CardContent> 
          </Card>
          <Grid item p={1.5}>
            {" "}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 1.9)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                      stroke: "#025b95",
                    },
                  }),
                }}
                spinner
                text="Loading ..."
              >
                <Card>
                  <CardContent>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchList(e.target.value)}
                      />
                    </Search>

                    <FormControl fullWidth size="small" sx={{ my: 2 }}>
                      <InputLabel htmlFor="grouped-select">
                        Select Category
                      </InputLabel>
                      <Select
                        defaultValue=""
                        id="grouped-select"
                        label="Select type of Category "
                        onChange={(e) => tutorfilterChange(e.target.value)}
                      >
                        {tutors.map((value, index) => {
                          return (
                            <MenuItem value={value.id}>{value.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </CardContent>
                </Card>
              </LoadingOverlay>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 1.9)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                      stroke: "#025b95",
                    },
                  }),
                }}
                spinner
                text="Loading ..."
              >
                <Card>
                  <CardContent>
                    <List>
                      {lists.map((value, index) => {
                        return (
                          <Grid container spacing={3}>
                            <Grid item xs={2} sm={1} md={1} lg={1}>
                              <ListItemAvatar>
                                <Avatar
                                  alt="education listing"
                                  src={value.image}
                                />
                              </ListItemAvatar>
                            </Grid>
                            <Grid item xs={10} sm={6} md={7} lg={7}>
                              <ListItemText
                                className="cp"
                                color="text.primary"
                                onClick={() => DetailView(value.id, value.type)}
                                primary={value.title}
                                secondary={
                                  <React.Fragment>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        p: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {value.city}
                                      </Typography>
                                    </Box>
                                  </React.Fragment>
                                }
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} md={2} lg={2}>
                              Doctors
                            </Grid>
                            <Grid item xs={6} sm={2} md={2} lg={2}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "end",
                                  flexDirection: "column",
                                }}
                              >
                                <Button
                                  className="newBtn"
                                  size="small"
                                  color="info"
                                  onClick={() =>
                                    DetailView(value.id, value.type)
                                  }
                                >
                                  Details
                                </Button>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              mb={1}
                              sx={{ padding: "0px" }}
                            >
                              {" "}
                              <Divider variant="inset" component="li" />{" "}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </List>

                    <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                      <Box p={2} display="flex" justifyContent="center">
                        <Stack spacing={2}>
                          <Pagination
                            count={total}
                            page={page}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Box>
                    </Grid>
                  </CardContent>
                </Card>
              </LoadingOverlay>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </div>
  );
}
