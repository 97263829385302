import {
    Box,
    Card,
    CardContent,
    IconButton,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    FormControl,
    Container,
    createTheme,
    Divider,
    Grid,
    ThemeProvider,
    Typography,
    Button,
  } from "@mui/material";
  import React from "react";
  import Footer from "../footer/Footer";
  import Header from "../header/Header";
  import Visibility from "@mui/icons-material/Visibility";
  import VisibilityOff from "@mui/icons-material/VisibilityOff";
  import ArrowBackIcon from '@mui/icons-material/ArrowBack';
  import { Link, useHistory } from "react-router-dom";
  import axios from "../utils/Axios"; //import axios config
  import { useAlert } from "react-alert";
  
  const theme = createTheme({
    palette: {
      primary: {
        light: "#9ab674",
        main: "#6f973d",
        dark: "#2E5800",
        contrastText: "#fff",
      },
      secondary: {
        light: "#acacac",
        main: "#676767",
        dark: "#3f3a3a",
      },
    },
  });
  export default function Settings() {
    const alert = useAlert();
    const [values, setValues] = React.useState({
      amount: "",
      password: "",
      weight: "",
      weightRange: "",
      showPassword: false,
    });
  
    const [newpass, setNewpass] = React.useState({
      amount: "",
      password: "",
      weight: "",
      weightRange: "",
      showPassword: false,
    });
  
  
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  
    const handleChange2 = (prop) => (event) => {
      setNewpass({ ...newpass, [prop]: event.target.value });
    };
  
    const handleClickShowPassword = () => {
      setValues({
        ...values,
        showPassword: !values.showPassword,
      });
    };
  
    const handleClickShowPassword2 = () => {
      setNewpass({
        ...newpass,
        showPassword: !newpass.showPassword,
      });
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const [loading, setLoading] = React.useState(true);
  
    const changePassword = () => {
      let formdata = new FormData();
      formdata.append(
        "userpassword",
        values.password ? values.password : ""
      );
      formdata.append(
        "newpassword",
        newpass.password ? newpass.password : ""
      );
      setLoading(true);
      axios(`Settings/update_password`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status) {
            alert.success(res.data.message);
            setLoading(false);
          } else {
            alert.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    };
  
    return (
      <div>
        <Header />
        <Box
          sx={{ bgcolor: "#f5f5f5" }}
          py={{ xs: "1rem", sm: "2rem", md: "3rem", lg: "4rem" }}
        >
          <Container maxWidth="sm">
            <ThemeProvider theme={theme}>
              <Box
                pt={{ xs: "100px", sm: "60px", md: "48px", lg: "48px" }}
                pb={{ xs: "16px", sm: "16px", md: "48px", lg: "48px" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                   <Grid container item spacing={3}>
                    <Grid item xs={10} sm={6}>
                    <Typography
                      variant="h4"
                      color="primary"
                      fontWeight={700}
                      gutterBottom
                    >
                      Settings
                    </Typography>
                    </Grid>
                      <Grid item xs={2} sm={6}><Link to="/">  <Typography textAlign="end"><IconButton><ArrowBackIcon/></IconButton></Typography> </Link></Grid>  </Grid>
  
                  
                  </Grid>
                  <Grid item xs={12} spacing={3}>
                    <Card>
                      <CardContent>
                        <Typography
                          variant="h6"
                          color="text.primary"
                          fontWeight={600}
                          gutterBottom
                        >
                          Password
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                        >
                          Update password
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControl
                              sx={{ my: 2, width: "100%" }}
                              variant="outlined"
                            >
                              <InputLabel htmlFor="outlined-adornment-password">
                                Current Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={handleChange("password")}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {values.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Password"
                              />
                            </FormControl>
  
                            <FormControl
                              sx={{ my: 2, width: "100%" }}
                              variant="outlined"
                            >
                              <InputLabel htmlFor="outlined-adornment-password">
                                New Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                type={newpass.showPassword ? "text" : "password"}
                                value={newpass.password}
                                onChange={handleChange2("password")}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword2}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {newpass.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Confirm Password"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                          </Grid>
                          <Grid item xs={12}>
                            <Button variant="contained" onClick={(e) => changePassword()}>Update</Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </ThemeProvider>
          </Container>
        </Box>
  
        <Footer />
      </div>
    );
  }
  