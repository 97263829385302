import * as React from "react";
import {Container,Grid,Typography,Button,Box,Avatar} from "@mui/material";
import icon1 from "../images/icons/icon1.png";
import icon2 from "../images/icons/icon2.png";
import icon3 from "../images/icons/icon3.png";
import icon4 from "../images/icons/icon4.png";
import icon5 from "../images/icons/icon5.png";
import icon6 from "../images/icons/icon6.png";

export default function OurApproach() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} bgcolor="#F2F2F2">
        <Grid Item xs={12} md={4} alignSelf="center">
          <Container maxWidth="xs">
            <Typography
              variant="h4"
              component="h2"
              textTransform="uppercase"
              fontWeight={600}
              gutterBottom
            >
              Our Approach
            </Typography>
            <Typography variant="h4" component="p" gutterBottom>
              Investing in a diverse, global community of changemakers
            </Typography>
            {/* <Button variant="outlined" color="inherit" backgroundColor="#fff">
              Read more
            </Button> */}
          </Container>
        </Grid>
        <Grid Item xs={12} md={8} bgcolor="#fff">
          <Container maxWidth="lg">
            <Grid container spacing={0}>
              <Grid Item xs={12} md={4} p={4}>
                <Avatar src={icon1} sx={{ width: 81, height: 81, pb: 2 }} />
                <Typography
                  variant="h6"
                  color="textPrimary"
                  component="h2"
                  gutterBottom
                >
                  Connection & Education
                </Typography>
                <Typography variant="p" color="gray" component="p" gutterBottom>
                  We leverage our global expertise, platforms, and unparalleled
                  convening power to inspire educators, youth, and future
                  Explorers and help more people learn about, care for, and
                  protect our world.
                </Typography>
              </Grid>
              <Grid Item xs={12} md={4} p={4}>
                <Avatar src={icon2} sx={{ width: 81, height: 81, pb: 2 }} />
                <Typography
                  variant="h6"
                  color="textPrimary"
                  component="h2"
                  gutterBottom
                >
                  Bold Explorers
                </Typography>
                <Typography variant="p" color="gray" component="p" gutterBottom>
                  We leverage our global expertise, platforms, and unparalleled
                  convening power to inspire educators, youth, and future
                  Explorers and help more people learn about, care for, and
                  protect our world.
                </Typography>
              </Grid>
              <Grid Item xs={12} md={4} p={4}>
                <Avatar src={icon3} sx={{ width: 81, height: 81, pb: 2 }} />
                <Typography
                  variant="h6"
                  color="textPrimary"
                  component="h2"
                  gutterBottom
                >
                  Agro Tech
                </Typography>
                <Typography variant="p" color="gray" component="p" gutterBottom>
                  We leverage our global expertise, platforms, and unparalleled
                  convening power to inspire educators, youth, and future
                  Explorers and help more people learn about, care for, and
                  protect our world.
                </Typography>
              </Grid>
              <Grid Item xs={12} md={4} p={4}>
                <Avatar src={icon4} sx={{ width: 81, height: 81, pb: 2 }} />
                <Typography
                  variant="h6"
                  color="textPrimary"
                  component="h2"
                  gutterBottom
                >
                  Impactful Programs
                </Typography>
                <Typography variant="p" color="gray" component="p" gutterBottom>
                  We leverage our global expertise, platforms, and unparalleled
                  convening power to inspire educators, youth, and future
                  Explorers and help more people learn about, care for, and
                  protect our world.
                </Typography>
              </Grid>
              <Grid Item xs={12} md={4} p={4}>
                <Avatar src={icon5} sx={{ width: 81, height: 81, pb: 2 }} />
                <Typography
                  variant="h6"
                  color="textPrimary"
                  component="h2"
                  gutterBottom
                >
                  Responsible Stewardship
                </Typography>
                <Typography variant="p" color="gray" component="p" gutterBottom>
                  We leverage our global expertise, platforms, and unparalleled
                  convening power to inspire educators, youth, and future
                  Explorers and help more people learn about, care for, and
                  protect our world.
                </Typography>
              </Grid>
              <Grid Item xs={12} md={4} p={4}>
                <Avatar src={icon6} sx={{ width: 81, height: 81, pb: 2 }} />
                <Typography
                  variant="h6"
                  color="textPrimary"
                  component="h2"
                  gutterBottom
                >
                  Supply Chain
                </Typography>
                <Typography variant="p" color="gray" component="p" gutterBottom>
                  We leverage our global expertise, platforms, and unparalleled
                  convening power to inspire educators, youth, and future
                  Explorers and help more people learn about, care for, and
                  protect our world.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
}
