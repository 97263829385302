import React, { useState } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, Box, Paper, Typography, Stack } from "@mui/material";
import axios from "../../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { makeStyles } from "@material-ui/core/styles";
import image2 from "../../../images/logo.webp";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const useStyle = makeStyles({
  image2: {
    borderRadius: 100,
  },
});

export default function MyAccountEditNewsAndEventsDialog({
  saahe_id,
  getNews,
}) {
  const [value, onChange] = useState(new Date());

  const [status, setStatus] = React.useState("");

  const StatushandleChange = (event) => {
    setStatus(event.target.value);
  };

  const alert = useAlert();
  // const [value, setValue] = React.useState(new Date());
  const [image, setImage] = React.useState("");
  const [awardsName, setAwards_name] = React.useState("");
  const [awardsDescription, setDescription] = React.useState("");

  const [uploadImage, setUploadImage] = useState("");
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("id", saahe_id);
    formdata.append("title", awardsName);
    formdata.append("logo", image);
    formdata.append("newsDescription", awardsDescription);
    //formdata.append("event_date", value);
    formdata.append("status", status);
    axios(`Izra/add_news`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getNews(saahe_id);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add News and Events
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add News and Events</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    required
                    label="News and Events Name"
                    variant="outlined"
                    value={awardsName}
                    onChange={(e) => setAwards_name(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Status"
                      value={status}
                      onChange={StatushandleChange}
                    >
                      <MenuItem value={1}>Enable</MenuItem>
                      <MenuItem value={2}>Desable</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                <Stack component="form" noValidate spacing={3}>
                <TextField
                  size="small"
                  id="date"
                  label="DatePicker"
                  type="date"
                  defaultValue="2022-05-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Stack>
                 
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <Paper style={{ width: "100%" }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <input
                        type="file"
                        accept="image2/*"
                        name="image2"
                        id="file"
                        onChange={loadFile}
                        style={{ display: "none" }}
                      />
                      <Typography variant="caption">
                        News and Events Thumbnail{" "}
                      </Typography>

                      <img
                        src={uploadImage ? uploadImage : image2}
                        className={classes.image2}
                        id="output"
                        width="200"
                        alt="test"
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      padding="10px 20px"
                    >
                      <label htmlFor="file" style={{ cursor: "pointer" }}>
                        <PhotoCameraIcon />
                      </label>
                    </Box>
                  </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Description of the News and Events"
                multiline
                rows={4}
                value={awardsDescription}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="contained"
            onClick={submitForm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
